/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  margin-right: -10px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */;
  padding-right: 10px;
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}

.my-masonry-grid_column_detail {
  padding-left: 5px; 
  padding-right: 5px;/* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column_detail > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}

.my-masonry-grid_detail {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;/* gutter size offset */
  width: auto;
}

.lazy-load-image-background{
  display: block !important;
}

.ReactModal__Overlay{
  z-index: 9999 !important;
}

.arrow-prev{
  cursor: pointer;
}

.arrow-next{
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.control-dots{
  margin: 2px 0 !important;
}
.dot{
  width: 6px !important;
  height: 6px !important;
}
.carousel-root{
  width: 100%;
}